<script setup>
import { inject, reactive, ref } from 'vue';

const _this = inject('plugin');
const ctx = inject('ctx');
const $require = inject('$require');
let pzconfim = ref(null);
let pageData = ref({});
console.log(ctx);
document.title = ctx.router.currentRoute.value.query.title;
/**
 * 获取页面数据
 * */
let searchForm = reactive({
  pageNum: 1,
pageSize: 10,
 contract_fee_name: ctx.route.query.title,
});
let getList = () => {
  $require.HttpPost('/h5/o_other/otherBill', searchForm)
  .then((res) => {
    if (res.data) {
      pageData.value = res.data;
    }
  });
};
getList();
// 上拉加载
let loading = ref(false);
let finished = ref(false);

let pageTotal = ref(0);
let onLoad = () => {
      loading.value = false;
      if (searchForm.pageNum >= Math.ceil(pageTotal.value / searchForm.pageSize)) {
        finished.value = true;
      } else {
       searchForm.pageNum++;
        getList();
      }
};

// --
const goUrl = (row) => {
  ctx.router.push(`/account/promiseDeatail?id=${row.bill_offline_id}&title=${ctx.route.query.title}`);
};

</script>
<template>
  <div class="earnmoney" v-if="pageData">
    <div class="top">
      <div>{{ ctx.router.currentRoute.value.query.title }}</div>
      <div>￥{{ pageData.money }}</div>
    </div>
    <!-- list -->
    <div class="list-box">
      <div class="record">变动记录</div>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :offset="30"
          finished-text="没有更多了"
          @load="onLoad"
        >
      <div class="list" @click="goUrl(item)" v-for="(item,index) in pageData.list?pageData.list:[]" :key="index">
        <div class="left">
          <div class="title">缴纳:</div>
          <div class="time">{{item.pay_time}}</div>
        </div>
        <div class="right">
          <div>
            <div class="paychar add" >+{{item.key_pay_price}}</div>
          </div>
          <img src="../../assets/image/my/y.png" alt/>
        </div>
      </div>
      </van-list>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.earnmoney {
  padding: 20px 24px 0 24px;
  box-sizing: border-box;

  .top {
    width: 100%;
    height: 240px;
    background: url("../../assets/image/my/bzjbg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;

    div {
      &:nth-child(2) {
        font-size: 60px;
        font-weight: 800;
        margin-top: 39px;
      }
    }
  }

  .list-box {
    width: 100%;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 20px;
    padding: 0 30px;
    box-sizing: border-box;

    .record {
      padding: 30px 0;
      box-sizing: border-box;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      border-bottom: 1px solid #EFEFEF;
    }

    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 126px;
      border-bottom: 1px solid #efefef;
      padding: 30px 0;
      box-sizing: border-box;

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 13px;
        height: 22px;
      }

      .left {
        .title {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }

        .time {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          margin-top: 24px;
        }
      }

      .right {
        display: flex;
        align-items: flex-start;
        text-align: right;

        .paychar {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
        }

        .add {
          color: #FD6336;
        }

        img {
          width: 13px;
          height: 22px;
          margin-left: 28px;
          vertical-align: middle;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
